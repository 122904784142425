<template>
    <div class="page">
        <el-row :gutter="10">
            <el-col :span="showList ? 4 : 1" :class="['p_r treeData', showList ? '' : 'el-col-unfold']">
                <div :class="['unfoldClassifyBtn', showList ? 'el-icon-arrow-left':'el-icon-arrow-right']"
                     @click="showList = !showList"></div>
                <el-tree class="h100 p_all1" :data="treeData" :props="defaultProps" v-loading="treeLoading"
                         :highlight-current="highlight"
                         @node-click="selDepartment" v-show="showList">
                    <div slot-scope="{ node, data }" class="omit">
                        <el-tooltip class="item" effect="dark" :content="node.label" placement="top-start">
                            <div>{{ node.label }}</div>
                        </el-tooltip>
                    </div>
                </el-tree>
            </el-col>
            <el-col :span="showList ? 20 : 24" :class="[showList ? '' : 'el-col-unfold']">
                <el-form size="small" :inline="true" class="query-form" ref="searchForm" :model="searchForm" @keyup.enter.native="refreshList(1)">
                    <el-form-item prop="name" label="姓名：">
                        <el-input v-model.trim="searchForm.name" maxlength="50" placeholder="请输入姓名（50字内）"
                                  clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="refreshList(1)" size="small" icon="el-icon-search">查询
                        </el-button>
                        <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
                    </el-form-item>
                    <div class="f_r">
                        <el-button v-if="hasPermission('sys:user:add')" type="primary" size="small" icon="el-icon-plus"
                                   @click="add()">新增
                        </el-button>
                        <el-button v-if="hasPermission('sys:user:del')" type="danger" size="small" icon="el-icon-delete"
                                   @click="del()">批量删除
                        </el-button>
                    </div>
                </el-form>
                <div class="bg-white">
                    <div class="text_right">
                        <el-button v-if="hasPermission('sys:user:export')" size="small" @click="exportData()"><i
                                class="icon-piliangdaochu iconfont buIcon"/>
                            批量导出
                        </el-button>
                    </div>
                    <el-table
                            :data="dataList"
                            v-loading="loading"
                            @selection-change="selectionChangeHandle"
                            size="small"
                            height="calc(100vh - 328px)"
                            class="table" ref="multipleTable" row-key='id'>
                        <el-table-column
                                type="selection"
                                width="50" :reserve-selection="true">
                        </el-table-column>
                        <el-table-column
                                prop="name"
                                sortable
                                show-overflow-tooltip
                                label="姓名">
                            <template slot-scope="scope">
                                <el-link type="primary" :underline="false" v-if="hasPermission('sys:user:edit')"
                                         @click="edit(scope.row)">{{ scope.row.name }}
                                </el-link>
                                <el-link type="primary" :underline="false" v-else-if="hasPermission('sys:user:view')"
                                         @click="view(scope.row)">{{ scope.row.name }}
                                </el-link>
                                <span v-else>{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="photo"
                                label="头像">
                            <template slot-scope="scope">
                                <el-avatar size="small" :src="scope.row.image"></el-avatar>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="sex"
                                label="性别">
                            <template slot-scope="scope">
                                {{ scope.row.sex == 2 ? "保密" : (scope.row.sex == 0 ? "男" : "女") }}
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="mobilePhone"
                                label="手机号" show-overflow-tooltip>
                            <template slot-scope="scope">
                                {{ scope.row.mobilePhone | specialCharacter }}
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="logicType"
                                label="启用状态">
                            <template slot-scope="scope">
                                <el-switch v-model="scope.row.logicType" active-value="0" inactive-value="1"
                                           @change="enabledChange(scope.row)"></el-switch>
                            </template>
                        </el-table-column>
                        <el-table-column prop="departmentName" label="所在部门" show-overflow-tooltip></el-table-column>
                        <el-table-column prop="libraryName" label="所属机构" show-overflow-tooltip></el-table-column>
                        <el-table-column
                                prop="telPhone"
                                label="电话" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column
                                prop="email"
                                label="邮箱" show-overflow-tooltip>
                        </el-table-column>
                        <el-table-column
                                fixed="right"
                                width="220"
                                label="操作">
                            <template slot-scope="scope">
                                <el-button v-if="hasPermission('sys:user:view')" type="text" size="small"
                                           @click="view(scope.row)">详情
                                </el-button>
                                <el-button v-if="hasPermission('sys:user:edit')" type="text" size="small"
                                           @click="edit(scope.row)">修改
                                </el-button>
                                <el-button v-if="hasPermission('sys:user:editPassword')" type="text" size="small"
                                           @click="editPassword(scope.row)">修改密码
                                </el-button>
                                <el-button v-if="hasPermission('sys:user:unlock') && scope.row.loginRestrictions == 1"
                                           type="text" size="small" @click="unlock(scope.row)">
                                    解锁
                                </el-button>
                                <el-button v-if="hasPermission('sys:user:del')" type="text" size="small"
                                           @click="del(scope.row.id)">删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                            @size-change="sizeChangeHandle"
                            @current-change="currentChangeHandle"
                            :current-page="searchForm.current"
                            :page-sizes="[10, 20, 50, 100]"
                            :page-size="searchForm.size"
                            :total="total"
                            background
                            layout="total, sizes, prev, pager, next, jumper">
                    </el-pagination>
                </div>
            </el-col>
        </el-row>
        <user-form ref="userForm" @refreshDataList="refreshList"></user-form>
        <modify-password ref="modifyPassword" @refreshDataList="refreshList"></modify-password>
    </div>
</template>

<script>
    import UserForm from './UserForm'
    import ModifyPassword from "@/views/modules/sys/user/modifyPassword.vue";

    export default {
        data() {
            return {
                showList: true,
                treeData: [],
                treeLoading: false,
                defaultProps: {
                    children: 'children',
                    label: 'departmentName'
                },
                searchForm: {
                    name: '',
                    loginName: '',
                    current: 1,
                    size: 10,
                    orderBy: '',
                    department: '',
                    libraryId: '',
                },
                dataList: [],
                total: 0,
                dataListSelections: [],
                loading: false,
                highlight: false,
            }
        },
        components: {
            ModifyPassword,
            UserForm
        },
        activated() {
            this.searchTree()
            this.refreshList(1)
        },
        methods: {
            // 获取数据列表
            refreshList(type) {
                if (type == 1) {
                    this.searchForm.current = 1
                }
                if (type == 0) {
                    this.$refs.multipleTable.clearSelection()
                }
                this.loading = true
                this.$axios(this.api.user.sysuserQueryAllList, this.searchForm, 'get').then(data => {
                    if (data && data.status) {
                        this.dataList = data.data.records
                        if (this.dataList.length == 0 && this.searchForm.current > 1) {
                            this.searchForm.current--
                            this.refreshList()
                        }
                        this.total = parseInt(data.data.total)
                        this.loading = false
                    }
                })
            },
            // 查询树状数据
            searchTree() {
                this.treeLoading = true
                this.$axios(this.api.user.getSysDepartment, {}, 'post').then(res => {
                    if (res.status) {
                        this.treeData = res.data
                    }
                    this.treeLoading = false
                })
            },
            // 选择部门
            selDepartment(val) {
                this.highlight = true
                if (val.departmentType == 1) {
                    this.searchForm.department = val.id
                    this.searchForm.libraryId = ''
                } else {
                    this.searchForm.department = ''
                    this.searchForm.libraryId = val.id
                }
                this.refreshList(1)
            },
            // 启用 0启用 1禁用
            enabledChange(row) {
                row.type = 3
                this.$axios(this.api.user.sysuserUpdateById, row, 'post').then(data => {
                    if (data.status) {
                        this.$message.success(data.msg)
                    } else {
                        this.$message.error(data.msg)
                    }
                    this.refreshList()
                })
            },
            // 每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val
                this.searchForm.current = 1
                this.refreshList()
            },
            // 当前页
            currentChangeHandle(val) {
                this.searchForm.current = val
                this.refreshList()
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 新增
            add() {
                this.$refs.userForm.init('add', '')
            },
            // 修改
            edit(row) {
                this.$refs.userForm.init('edit', row)
            },
            // 查看
            view(row) {
                this.$refs.userForm.init('view', row)
            },
            //修改密码
            editPassword(row) {
                this.$refs.modifyPassword.init(row)
            },
            //解锁
            unlock(row) {
                this.$confirm(`确定解锁该用户吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$axios(this.api.user.removeLoginRestrictions + row.mobilePhone, {}, 'put').then(data => {
                        if (data.status) {
                            this.$message.success('解锁成功');
                        } else {
                            this.$message.error(data.msg);
                        }
                        this.refreshList(0);
                    })
                })
            },
            // 删除
            del(id) {
                if (!id && !this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                let ids = id || this.dataListSelections.map(item => {
                    return item.id
                }).join(',')
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading = true
                    this.$axios(this.api.user.sysuserRemove, {'ids': ids}, 'delete').then(data => {
                        if (data.status) {
                            this.$message.success('删除成功');
                        } else {
                            this.$message.error(data.msg);
                        }
                        this.refreshList(0);
                        this.loading = false
                    })
                })
            },
            // 导出
            exportData() {
                if (!this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                let ids = this.dataListSelections.map(item => {
                    return item.id
                })
                this.exportExcel(this.api.user.exportUserList, ids, '用户数据', 'post')
            },
            resetSearch() {
                this.highlight = false
                this.searchForm.department = ''
                this.searchForm.libraryId = ''
                this.$refs.searchForm.resetFields();
                this.$refs.multipleTable.clearSelection()
                this.refreshList(1)
            }
        }
    }
</script>
<style scoped>
    .treeData {
        height: calc(100vh - 124px);
        overflow: auto;
    }
</style>
